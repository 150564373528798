import logo from './logo.png';
import autismboy from './autism-boy.png';
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
          PAPER CRANE LLC
        <div className='App-paragraph'>
        A warm-hearted game developer and publisher. 
        </div>
        <img src={autismboy} className='App-logo'/>
        <div className='App-paragraph'>
        We are launching a "Game Helps Children with Autism Program"
        <a href='https://github.com/papercranellc/ghcap'>(GHCAP)</a>. 
        If you have any idea, please contact us.
        </div>
        <div className='App-address'>
          Email: <a href="mailto:dev@papercranellc.com">dev@papercranellc.com</a>
        </div>
        <div className='App-address'>
        Address: 2020 N ACADEMY BLVD, STE 261 #2278	
        COLORADO SPRINGS, CO 80909
        </div>
        
        <div className='App-copyright'>
          Copyright © 2023 PAPER CRANE LLC. All images are created by AI technology.
        </div>
      </header>
    </div>
  );
}

export default App;
